export const general = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    dataProtection: "Your data is always protected with us. Read our <a href='/privacy-policy'>Privacy Policy</a>.",
    allFieldsError: "Oops! Looks like you missed a field. Please complete all fields.",
}

export const homeContent = {
    how: "How It Works",
    dataProtection: "Data Protection",
    faq: "FAQs",
}

export const heroContent = {
    description: "The email is valid!",
    nameTitle: "Name of Person",
    companyTitle: "Company website",
    dynamicMessages: {
        "resultsMessage": "Found {count} results",
    },
    signupFree: "FIND EMAIL",
    dataProtection: general.dataProtection,
    allFieldsError: general.allFieldsError,
    startMessage: "Find your first email",
    manyAttempts: "Too many attempts, try again later",
    noEmailsFound: "No valid emails found.",
    catchAllEnabled: "We can't find emails from this domain because the email server has catch-all enabled.",
    copied: "Copied to clipboard!",
    copyFailed: "Failed to copy!",
    firstName: "First Name",
    lastName: "Last Name",
    example: "Example microsoft.com",
    loading: "Loading...",
};

export const aboutContent = {
    title1: "What is Lead Bust?",
    desc1: "Lead Bust is a simple little tool for finding a professional email address. It's free and quite easy to use. Just enter the name, domain name and hit the FIND EMAIL button. Sometimes, it's unable to find an email because some email providers have put limits and restrictions or they simply don't like any verification checks on their mailboxes.",
    title2: "How do we find an email?",
    desc2: "<ul class='checkmark-list'><li>First we generate a list of potential emails.</li><li>Then we send them to our email verification service.</li><li>Extract the MX records and connect to the email server.</li><li>Verify the potential emails</li></ul><br/>Some email servers do not cooperate and in such cases the result may not be as accurate as expected.",
};

export const howItWorks = {
    title: "Title",
    subtitle: "Subtitle",
    stepOneTitle: "stepOneTitle",
    stepOneSubtitle: "stepOneSubtitle",
    stepTwoTitle: "stepTwoTitle",
    stepTwoSubtitle: "stepTwoSubtitle",
    stepThreeTitle: "stepThreeTitle",
    stepThreeSubtitle: "stepThreeSubtitle",
}

export const faqContent = {
    title: "FAQs",
    subtitle: "Your Questions Answered",
    faqData: [
        {
            id: "1",
            question: "How does it work?",
            answer: "answer for how it works"
        },
        {
            id: "2",
            question: "How much does it cost?",
            answer: "answer for how much it costs"
        },
        {
            id: "3",
            question: "Question 3",
            answer: "Answer 3"
        }
    ],
}

export const startedContent = {
    text: "<span>Lead Bust</span> is a free little tool that helps you find professional email addresses with ease. Just enter a name and company domain, and let us find the right contact for your networking and lead generation needs!",
}
export const infoContent = {
    title: "What is Lead Bust?",
    text: "Lead Bust is a simple tool for finding email addresses. It's free and easy to use. Just enter the name and domain name and hit the search button. It tells you whether the email is real or fake. Sometimes it's unable to correctly predict and say unknown because some email providers have put some limits and restrictions or they simply don't like any verification checks on their mailboxes.",
}

export const footerContent = {
    copy: "© LeadBust.com. All Rights Reserved • <a href='/privacy-policy'>Privacy Policy</a> • <a href='/terms'>Terms and Conditions</a>",
}

export default heroContent;
import "./Hero.css";
import React, { useState, useEffect } from "react";
import { heroContent } from "../../content";
import EmptyIcon from '../../assets/logo2.svg';
import formatMessage from '../../utils/Utils';
import CopyIcon from '../../assets/copy-icon.svg';

const Hero = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [emailResults, setEmailResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [domainName, setDomain] = useState("");
  const [resultText, setResultText] = useState("");
  const [infoText, setInfoText] = useState("");
  const [copyFeedback, setCopyFeedback] = useState({ visible: false, message: '' });

  // Domain validation function
  const isValidDomain = (domain) => {
    const regex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
    return regex.test(domain);
  };

  const findEmails = async (e) => {
    e.preventDefault();
    if (!isValidDomain(domainName)) {
      setErrorMessage("Please enter a valid domain.");
      return;
    }
    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await fetch('https://api.fraudemail.com/find_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstname: firstName, lastname: lastName, domain: domainName }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.message && data.message === "catchall") {
        setResultText();
        setEmailResults([]);
        setInfoText(heroContent.catchAllEnabled);
      } else if (data.length) {
        const validEmails = data.filter(item => item.status === "valid");
        setResultText(formatMessage(heroContent.dynamicMessages.resultsMessage, { count: validEmails.length }));
        setEmailResults(validEmails);
        setInfoText();
      } else {
        setResultText();
        setEmailResults([]);
        setInfoText(heroContent.noEmailsFound);
      }
    } catch (error) {
      setErrorMessage(heroContent.manyAttempts);
    } finally {
      setIsLoading(false);
    }
  };

  const copyResult = (index) => {
    const emailToCopy = emailResults[index].email;
    navigator.clipboard.writeText(emailToCopy)
      .then(() => {
        setCopyFeedback({ visible: true, message: heroContent.copied });
        setTimeout(() => setCopyFeedback({ visible: false, message: '' }), 3000);
      })
      .catch(err => {
        setCopyFeedback({ visible: true, message: heroContent.copyFailed });
        setTimeout(() => setCopyFeedback({ visible: false, message: '' }), 3000);
      });
  };



  return (
    <div className="hero" id="hero">
      {copyFeedback.visible && (
        <div className="copy-feedback">{copyFeedback.message}</div>
      )}
      <div className="firstHome">
        <div className="signupFormContainer">
          <form className="signup" onSubmit={findEmails}>
            <h3>{heroContent.nameTitle}</h3>
            <div className="name-fields">
              <input
                type="text"
                className="name-input"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={heroContent.firstName}
                required
              />
              <input
                type="text"
                className="name-input"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={heroContent.lastName}
                required
              />
            </div>
            <h3>{heroContent.companyTitle}</h3>
            <input
              className="field-input"
              value={domainName}
              onChange={(e) => setDomain(e.target.value)}
              placeholder={heroContent.example}
              required
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button className="action-btn" type="submit" disabled={isLoading}>
              {isLoading ? heroContent.loading : heroContent.signupFree}
            </button>
            <span className="signup-notice" dangerouslySetInnerHTML={{ __html: heroContent.dataProtection }}></span>
          </form>
          <p className="heroPara">{heroContent.heroNote}</p>
        </div>
        <div className="heroContentContainer">
          <div className="hero_content">
            <h1 id="status">{resultText}</h1>
            {emailResults.length > 0 ? (
              <div className="result-chips">
                {emailResults.map((result, index) => (
                  <div key={index} className={`chip ${result.status.toLowerCase()}`}>
                    <span>{result.email.toLowerCase()}</span>
                    <button onClick={() => copyResult(index)} className="copy-btn">
                      <img src={CopyIcon} alt="Copy" />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-state">
                <img src={EmptyIcon} alt="No Data" />
                <p>{infoText || heroContent.startMessage}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';
import './PrivacyPolicy.css';
import Logo from "../components/Logo/Logo";
import Footer from "../components/Footer/Footer";

const PrivacyPolicy = () => {
    return (
        <>
            <Logo />
            <div class="page-wrapper">
                <div class="intro">
                    <h1>Privacy Policy</h1>
                </div>
                <div class="jumbotron jumbotron-white">
                    <div>
                        <div>
                            <div>
                                <div class="c21 doc-content">
                                    <p class="c1"><span class="c2">The following sets forth the policies for the collection and use of personally
                                        identifiable information (&ldquo;Information&rdquo;) by PixelCraft LLC in connection with its operation of
                                        the Web site located at www.leadbust.com (the &ldquo;Site&rdquo;). &nbsp;PixelCraft takes its obligations
                                        regarding privacy very seriously, and it wants to ensure users are fully informed about the Information they
                                        are providing to it.</span></p>
                                    <p class="c1"><span class="c7">Collection of Information</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">In connection with the Site, PixelCraft may collect Information in the following
                                        ways:</span></p>
                                    <ul class="c13 lst-kix_ilm9sjskl50q-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Through registration forms filled out by a user on the Site</span>
                                        </li>
                                        <li class="c0 li-bullet-0"><span class="c2">Through Information provided by a user in connection with the
                                            purchase of products or services on the Site</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Through the maintenance and analysis of Web server logs</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Via e-mail sent by a user to PixelCraft</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Through the use by PixelCraft of third-party databases from which
                                            user Information is extracted and combined with Information obtained by PixelCraft through other
                                            means.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c2">PixelCraft may also connect non-personally identifiable information from users via
                                        &ldquo;cookies&rdquo; (small text files placed by PixelCraft on user computers), single-pixel GIF image
                                        files (also called &ldquo;Web beacons&rdquo;), Web server log analysis and other similar technological
                                        means. &nbsp;Such non-personally identifiable information may be used to track site trends and enhance the
                                        user experience, and may be shared with third parties.<br />To the extent third parties may place advertising
                                        on the Site, such third parties may utilize cookies or other technological means within the advertising to
                                        collect and utilize non-personally identifiable information. &nbsp;PixelCraft is not responsible for
                                        information collected by third parties in this manner, nor for the collection or use of Information by other
                                        sites to which the Site is linked.</span></p>
                                    <p class="c1"><span class="c7">Types of Information Collected by PixelCraft</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">The following types of Information about a user are among those that may be collected
                                        by PixelCraft in connection with the Site:</span></p>
                                    <ul class="c13 lst-kix_19t0kznusche-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Name</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Postal address</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">E-mail address</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Telephone number</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Cellular telephone number</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Facsimile number</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Payment information (e.g. credit card numbers and billing
                                            addresses), if purchases or payments are made</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Banking information</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Social Security number</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Date of birth</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Age except with any offer of credit</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Gender except with any offer of credit</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Other demographic information (e.g. occupation, income range)</span>
                                        </li>
                                        <li class="c0 li-bullet-0"><span class="c2">IP Address</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Referring site</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Other technical information collected by the Site&rsquo;s
                                            servers.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c7">Sharing Personal Information with Third Parties</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">We may share, sell or trade your personal information gathered online with third
                                        parties. We will share your information in order to provide services and process your requests. We may share
                                        your personal information with our corporation affiliates and businesses united within the same
                                        corporation.</span></p>
                                    <p class="c1"><span class="c2">By completing the online form you expressly consent to receive text message,
                                        autodialed and/or prerecorded calls from us, our marketing partners and the parties listed on the Site. I
                                        understand that consenting is not required to make any purchase.</span></p>
                                    <p class="c1"><span class="c7">No Collection of Information from Children</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">The Site is not intended for users under the age of 18, nor does PixelCraft knowingly
                                        collect or retain Information in connection with the Site from children under the age of 13.</span></p>
                                    <p class="c1"><span class="c7">Use of Information</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">PixelCraft may use Information collected in connection with the Site in the following
                                        ways:</span></p>
                                    <ul class="c13 lst-kix_a0tuorpsvj0k-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">To provide requested information, products and services to
                                            users</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To improve the user experience with the Site</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">In connection with the operation of the Site and PixelCraft internal
                                            business</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">In connection with other Sites or offline businesses owned or
                                            operated by PixelCraft</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">For Users to obtain information and offers for products and services
                                            offered by PixelCraft as well as selected third parties.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c2">In order to do the foregoing, PixelCraft may provide your Information to trusted
                                        third parties, including but not limited to selected third party marketers and vendors as well as third
                                        party contractors providing services to PixelCraft for the operation of the Site and its business,
                                        communication services and fulfillment of orders. Email address will only be provided to a select mailing
                                        partner for the purpose of sending third-party promotional emails. PixelCraft will use commercially
                                        reasonable efforts to limit use of the Information by such third parties to the specific uses set forth
                                        above. &nbsp;PixelCraft also utilizes electronic and physical security to reduce the risk of improper access
                                        to or manipulation of Information during transmission and storage, but cannot guarantee the security or
                                        integrity of the Information.</span></p>
                                    <p class="c1"><span class="c7">Access to Information</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">Users may modify and correct certain Information through a written request sent to
                                        PixelCraft LLC, 16192 COASTAL HWY, LEWES, Sussex, DE, 19958.</span></p>
                                    <p class="c1"><span class="c7">Changes to this Privacy Policy</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">PixelCraft reserves the right to revise and update this Privacy Policy at any time.
                                        Any such revisions will be effective on the date of posting to the Site, and will apply to all information
                                        collected by PixelCraft both prior to and following the effective date. Your use of the Site following any
                                        such revisions will be deemed your acceptance of such revisions. Users should periodically visit this page
                                        to review the current policies with regard to Information.</span></p>
                                    <p class="c1"><span class="c7">Opting Out from Offers from PixelCraft</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">At any time, a user may opt out from receiving future offers from PixelCraft by
                                        following the instructions contained within each marketing communication. &nbsp;Such opting out will not
                                        apply to any communications from third parties to whom PixelCraft may have provided Information regarding
                                        the user. &nbsp;Third parties&rsquo; use of the Information is subject to such parties&rsquo; own privacy
                                        policies, for which PixelCraft shall not be responsible.</span></p>
                                    <p class="c1"><span class="c7">Your California Privacy Rights</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">Beginning on January 1, 2005, California Civil Code Section 1798.83 permits customers
                                        of PixelCraft who are California residents to request certain information regarding PixelCraft&rsquo;s
                                        disclosure of personal information for their direct marketing purposes. To make such a request, please write
                                        to: PixelCraft LLC, 16192 COASTAL HWY, LEWES, Sussex, DE, 19958. &nbsp;Within thirty days of receiving such
                                        a request, PixelCraft will provide a list of the categories of personal information disclosed to third
                                        parties for third-party direct marketing purposes during the immediately preceding calendar year, along with
                                        the names and addresses of these third parties. This request may be made no more than once per calendar
                                        year. &nbsp;PixelCraft reserves its right not to respond to requests submitted other than to the address
                                        specified in this paragraph.</span></p>
                                    <p class="c1"><span class="c2">Many browsers including Internet Explorer, Firefox, and Google Chrome contain a
                                        &ldquo;do not track&rdquo; optional setting. In general, when a &ldquo;do not track&rdquo; setting is
                                        active, the user&rsquo;s browser notifies websites that the user does not want the user&rsquo;s personally
                                        identifiable information about an individual consumer&rsquo;s online activities tracked over time and across
                                        different Web sites or online services. Websites are not required to honor a do not track request and as
                                        required by amendments to the California Shine the Light law, we are informing you that we do not honor such
                                        requests.</span></p>
                                    <p class="c1"><span class="c7">Arbitration Agreement</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">Please read this Arbitration Agreement (the &ldquo;Agreement&rdquo;) carefully. THIS
                                        AGREEMENT STARTS WHEN YOU ACCEPT. You accept when you do any of the following things after an opportunity to
                                        review this agreement: give us a written or electronic signature; tell us orally or electronically that you
                                        accept; submit information to us on our website. This Agreement affects your legal rights and remedies and
                                        provides that disputes between you and PixelCraft LLC (the &ldquo;Company&rdquo;), must be resolved through
                                        binding arbitration rather than in a court.</span></p>
                                    <p class="c1"><span class="c7">Resolution Of Claims or Disputes</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">PixelCraft hopes to make you a happy customer and most customer concerns can be
                                        resolved quickly and to the customer&#39;s satisfaction by emailing our customer service department. If,
                                        however, there is an issue that needs to be resolved, this Agreement describes how both of us will proceed.
                                        Any claim or dispute between you and PixelCraft (or any of Company&rsquo;s subsidiaries or affiliates)
                                        arising out of or relating in any way to the product or this Agreement shall be resolved through final,
                                        binding arbitration. This obligation applies regardless of whether the claim or dispute involves a tort,
                                        fraud, breach of contract, misrepresentation, product liability, negligence, violation of a statute, or any
                                        other legal theory. Included are all claims arising out of or relating to any aspect of our relationship;
                                        claims that may arise after the termination of this Agreement; and claims related to direct marketing
                                        efforts, including complaints concerning unsolicited text messages, emails, and telemarketing calls.</span>
                                    </p>
                                    <p class="c1"><span class="c7">Limitation of Legal Remedies</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">We each agree that each of us may bring claims against the other only in an
                                        individual capacity and not in a class action or representative proceeding. All arbitrations under this
                                        Agreement shall be conducted on an individual (and not a class-wide) basis, and an arbitrator shall have no
                                        authority to award class-wide relief. You acknowledge and agree that this Agreement specifically prohibits
                                        you from commencing arbitration proceedings as a representative of others or joining in any arbitration
                                        proceedings brought by any other person.</span></p>
                                    <p class="c1"><span class="c7">Arbitration Procedures</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">A party who intends to seek arbitration must first send to the other a written Notice
                                        of Dispute describing the nature and basis of the claim or dispute and setting forth the specific relief
                                        sought. All Notices to PixelCraft shall be sent to the following address: PixelCraft LLC, 16192 COASTAL HWY,
                                        LEWES, Sussex, DE, 19958. Upon receipt of such Notice, the other party shall have a thirty-day period in
                                        which it may satisfy the claim against it by fully curing the dispute and/or providing all the relief
                                        requested in the Notice. After the expiration of such a thirty-day cure period, you or PixelCraft may
                                        commence an arbitration proceeding. The arbitration of any claim or dispute under this Agreement shall be
                                        conducted pursuant to the American Arbitration Association&rsquo;s (&ldquo;AAA&rdquo;) United States
                                        Commercial Dispute Resolution Procedures and Supplementary Procedures for Consumer-Related Disputes. These
                                        rules and procedures are available by calling the AAA or by visiting its web site at www.adr.org. The
                                        arbitration of any claim or dispute under this Agreement shall be conducted in the State of New York or in
                                        the location in which you received this Agreement or in your home state. For any non-frivolous claim that
                                        does not exceed $25,000, PixelCraft will pay all costs of the arbitration and will agree to conduct the
                                        arbitration through the AAA offices in your home state. For any claim under $10,000, PixelCraft further
                                        agrees that any hearings may be held by telephone and that PixelCraft will not seek attorney&rsquo;s fees in
                                        the event PixelCraft prevails. You acknowledge and agree that each party shall pay the fees and costs of its
                                        own counsel, experts and witnesses.</span></p>
                                    <p class="c1"><span class="c7">Choice of Law</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">This Agreement concerns a transaction in interstate commerce, and therefore shall be
                                        governed by the United States Federal Arbitration Act, 9 U.S.C. &sect; 1 et seq.</span></p>
                                    <p class="c1"><span class="c7">Severability</span><span class="c2">.</span></p>
                                    <p class="c1"><span class="c2">If any provision of this Agreement is declared or found to be unlawful, unenforceable
                                        or void, such provision will be ineffective only to the extent that it is found unlawful, unenforceable or
                                        void, and the remainder of the provision and all other provisions shall remain fully enforceable.</span></p>
                                    <p class="c1"><span class="c6">PRIVACY NOTICE FOR RESIDENTS OF CALIFORNIA, VIRGINIA, COLORADO, CONNECTICUT, AND
                                        UTAH</span></p>
                                    <p class="c1"><span class="c2">This PRIVACY NOTICE FOR RESIDENTS OF CALIFORNIA, VIRGINIA, COLORADO, CONNECTICUT, AND
                                        UTAH (&ldquo;Notice&rdquo;) supplements the information contained in the Privacy Policy and applies solely
                                        to those individuals who have consumer privacy rights under the following laws (&quot;consumers&quot; or
                                        &quot;you&quot;): the California Consumer Privacy Act of 2018, as amended by the California Privacy Rights
                                        Act (together &quot;CCPA&quot;), the Virginia Consumer Data Protection Act (&ldquo;VCDPA&rdquo;), the
                                        Colorado Privacy Act (&ldquo;CPA&rdquo;), the Connecticut Data Privacy Act (&ldquo;CTDPA&rdquo;), and the
                                        Utah Consumer Privacy Act (&ldquo;UCPA&rdquo;).</span></p>
                                    <p class="c1"><span class="c6">Information We Collect</span></p>
                                    <p class="c1"><span class="c2">We collect information that identifies, relates to, describes, references, is capable
                                        of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer
                                        or device (&ldquo;personal information&rdquo;). We have collected the following categories of personal
                                        information from consumers within the last twelve (12) months:</span></p><a
                                            id="t.16d46197ce59408214bba9ec0c6beec825608097"></a><a id="t.0"></a>
                                    <table class="c18">
                                        <tr class="c19">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c15"><span class="c12">Categories</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c15"><span class="c12">Examples</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c15"><span class="c12">Collected</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c24">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">A. Identifiers.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">A real name, alias, postal address, unique personal identifier, online
                                                    identifier, Internet Protocol address, email address, account name, Social Security number,
                                                    driver&rsquo;s license number, passport number, or other similar identifiers.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">YES</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c22">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">B. Personal information categories listed in the California Customer
                                                    Records statute (Cal. Civ. Code &sect; 1798.80(e)).</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">A name, signature, Social Security number, physical characteristics or
                                                    description, address, telephone number, passport number, driver&rsquo;s license or state
                                                    identification card number, insurance policy number, education, employment, employment history,
                                                    bank account number, credit card number, debit card number, or any other financial information,
                                                    medical information, or health insurance information. Some personal information included in this
                                                    category may overlap with other categories.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">YES</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c14">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">C. Protected classification characteristics under California or federal
                                                    law.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Age (40 years or older), race, color, ancestry, national origin,
                                                    citizenship, religion or creed, marital status, medical condition, physical or mental
                                                    disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth
                                                    and related medical conditions), sexual orientation, veteran or military status, genetic
                                                    information (including familial genetic information).</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">YES</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c8">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">D. Commercial information.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Records of personal property, products or services purchased, obtained,
                                                    or considered, or other purchasing or consuming histories or tendencies.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c28">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">E. Biometric information.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Genetic, physiological, behavioral, and biological characteristics, or
                                                    activity patterns used to extract a template or other identifier or identifying information,
                                                    such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
                                                    other physical patterns, and sleep, health, or exercise data.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c8">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">F. Internet or other similar network activity.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Browsing history, search history, information on a consumer&rsquo;s
                                                    interaction with a website, application, or advertisement.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">YES</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c19">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">G. Geolocation data.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Physical location or movements.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c11">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">H. Sensory data.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Audio, electronic, visual, thermal, olfactory, or similar
                                                    information.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c8">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">I. Professional or employment-related information.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Current or past job history or performance evaluations.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c14">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">J. Non-public education information (per the Family Educational Rights
                                                    and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Education records directly related to a student maintained by an
                                                    educational institution or party acting on its behalf, such as grades, transcripts, class lists,
                                                    student schedules, student identification codes, student financial information, or student
                                                    disciplinary records.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                        <tr class="c8">
                                            <td class="c10" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">K. Inferences drawn from other personal information.</span></p>
                                            </td>
                                            <td class="c5" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">Profile reflecting a person&rsquo;s preferences, characteristics,
                                                    psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and
                                                    aptitudes.</span></p>
                                            </td>
                                            <td class="c4" colspan="1" rowspan="1">
                                                <p class="c3"><span class="c2">NO</span></p>
                                            </td>
                                        </tr>
                                    </table>
                                    <p class="c1"><span class="c6">Public information does not include:</span></p>
                                    <ul class="c13 lst-kix_xfqicxg1pm5h-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Publicly available information from government records.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">De-identified or aggregated consumer information.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Information excluded from the CCPA, VCDPA, CPA, CTDPA and
                                            UCPA&rsquo;s scope, like:</span></li>
                                    </ul>
                                    <ul class="c13 lst-kix_xfqicxg1pm5h-1 start">
                                        <li class="c1 c16 li-bullet-0"><span class="c2">health or medical information covered by the Health Insurance
                                            Portability and Accountability Act of 1996 (HIPAA) and</span></li>
                                        <li class="c1 c16 li-bullet-0"><span class="c2">personal information covered by certain sector-specific privacy
                                            laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                                            Financial Information Privacy Act (FIPA), and the Driver&rsquo;s Privacy Protection Act of 1994.</span>
                                        </li>
                                    </ul>
                                    <p class="c1"><span class="c6">We obtain the categories of personal information listed above from the following
                                        categories of sources:</span></p>
                                    <ul class="c13 lst-kix_s9ozsorgu23n-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Directly from you.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Indirectly from you. For example, through information we collect
                                            from our clients in the course of providing services to them.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Directly and indirectly from activity on our website.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">From third-parties that interact with us in connection with the
                                            services we perform.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c6">Use of Personal Information</span></p>
                                    <p class="c1"><span class="c2">We may use or disclose the personal information we collect for one or more of the
                                        following business purposes:</span></p>
                                    <ul class="c13 lst-kix_nq5hw4cwe73g-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">To fulfill or meet the reason for which the information is
                                            provided.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To provide you with information, products or services that you
                                            request from us.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To provide you with email alerts.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To carry out our obligations and enforce our rights arising from any
                                            contracts entered into between you and us.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To improve our website and present its contents to you.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">For testing, research, analysis and product development.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">As necessary or appropriate to protect the rights, property or
                                            safety of us, our clients or others.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To respond to law enforcement requests and as required by applicable
                                            law, court order, or governmental regulations.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">As described to you when collecting your personal information or as
                                            otherwise set forth in the CCPA, VCDPA, CPA, CTDPA or UCPA.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">To evaluate or conduct a merger, divestiture, restructuring,
                                            reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going
                                            concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held
                                            by us is among the assets transferred.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c2">We will not collect additional categories of personal information or use the personal
                                        information we collected for materially different, unrelated, or incompatible purposes without providing you
                                        notice.</span></p>
                                    <p class="c1"><span class="c6">Sharing Personal Information</span></p>
                                    <p class="c1"><span class="c2">We may disclose your personal information to a third party for a business purpose.
                                        When we disclose personal information for a business purpose, we enter a contract that describes the purpose
                                        and requires the recipient to both keep that personal information confidential and not use it for any
                                        purpose except performing the contract.</span></p>
                                    <p class="c1"><span class="c2">In the preceding twelve (12) months, we have disclosed the following categories of
                                        personal information for a business purpose:</span></p>
                                    <p class="c1"><span class="c2">Category A: Identifiers.<br />Category B: Customer Records personal information
                                        categories.<br />Category F: Internet or other similar network activity.</span></p>
                                    <p class="c1"><span class="c2">We disclose your personal information for a business purpose to the following
                                        categories of third parties:</span></p>
                                    <ul class="c13 lst-kix_fc42y0um5yup-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Our affiliates.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Service providers.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Third parties to whom you or your agents authorize us to disclose
                                            your personal information in connection with products or services we provide to you.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Third party marketers who we feel you will benefit from their
                                            services.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c2">In the preceding twelve (12) months, we have not sold any personal
                                        information.</span></p>
                                    <p class="c1"><span class="c6">Your Rights and Choices</span></p>
                                    <p class="c1"><span class="c2">You have specific rights regarding their personal information. This section describes
                                        your CCPA, VCDPA, CPA, CTDPA and UCPA&rsquo;s rights and explains how to exercise those rights.</span></p>
                                    <p class="c1"><span class="c6">Access to Specific Information and Data Portability Rights</span></p>
                                    <p class="c1"><span class="c2">You have the right to request that we disclose certain information to you about our
                                        collection and use of your personal information over the past 12 months. Once we receive and confirm your
                                        verifiable consumer request, we will disclose to you:</span></p>
                                    <ul class="c13 lst-kix_xg750y6piywy-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">The categories of personal information we collected about
                                            you.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">The categories of sources for the personal information we collected
                                            about you.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Our business or commercial purpose for collecting or selling that
                                            personal information.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">The categories of third parties with whom we share that personal
                                            information.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">The specific pieces of personal information we collected about you
                                            (also called a data portability request).</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">If we sold or disclosed your personal information for a business
                                            purpose, two separate lists disclosing:</span></li>
                                    </ul>
                                    <ul class="c13 lst-kix_xg750y6piywy-1 start">
                                        <li class="c1 c16 li-bullet-0"><span class="c2">sales, identifying the personal information categories that each
                                            category of recipient purchased; and</span></li>
                                        <li class="c1 c16 li-bullet-0"><span class="c2">disclosures for a business purpose, identifying the personal
                                            information categories that each category of recipient obtained.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c6">Deletion Request Rights</span></p>
                                    <p class="c1"><span class="c2">You have the right to request that we delete any of your personal information that we
                                        collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable
                                        consumer request, we will delete (and direct our service providers to delete) your personal information from
                                        our records, unless an exception applies.</span></p>
                                    <p class="c1"><span class="c2">We may deny your deletion request if retaining the information is necessary for us or
                                        our service providers to:</span></p>
                                    <ol class="c13 lst-kix_r3ntrnkhio8v-0 start" start="1">
                                        <li class="c0 li-bullet-0"><span class="c2">Complete the transaction for which we collected the personal
                                            information, provide a good or service that you requested, take actions reasonably anticipated within
                                            the context of our ongoing business relationship with you, or otherwise perform our contract with
                                            you.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Detect security incidents, protect against malicious, deceptive,
                                            fraudulent, or illegal activity, or prosecute those responsible for such activities.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Debug products to identify and repair errors that impair existing
                                            intended functionality.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Exercise free speech, ensure the right of another consumer to
                                            exercise their free speech rights, or exercise another right provided for by law.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Engage in public or peer-reviewed scientific, historical, or
                                            statistical research in the public interest that adheres to all other applicable ethics and privacy
                                            laws, when the information&rsquo;s deletion may likely render impossible or seriously impair the
                                            research&rsquo;s achievement, if you previously provided informed consent.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Enable solely internal uses that are reasonably aligned with
                                            consumer expectations based on your relationship with us.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Comply with a legal obligation.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Make other internal and lawful uses of that information that are
                                            compatible with the context in which you provided it.</span></li>
                                    </ol>
                                    <p class="c1"><span class="c6">Exercising Access, Data Portability, and Deletion Rights</span></p>
                                    <p class="c1"><span class="c26">To exercise the access, data portability, and deletion rights described above,
                                        please submit a verifiable consumer request to us by either:</span></p>
                                    <ul class="c13 lst-kix_ofwh17erq4d-0 start">
                                        <li class="c0 li-bullet-0"><span class="c26">Emailing us at: privacy@pixelcraftllc.com</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">By writing us at: PixelCraft LLC, 16192 COASTAL HWY, LEWES, Sussex,
                                            DE, 19958</span></li>
                                    </ul>
                                    <p class="c1 c17"><span class="c2"></span></p>
                                    <p class="c1"><span class="c2">Only you or a person registered with the California Secretary of State that you
                                        authorize to act on your behalf, may make a verifiable consumer request related to your personal
                                        information. You may also make a verifiable consumer request on behalf of your minor child.</span></p>
                                    <p class="c1"><span class="c2">You may only make a verifiable consumer request for access or data portability twice
                                        within a 12-month period. The verifiable consumer request must:</span></p>
                                    <ul class="c13 lst-kix_y9u4dznllz5j-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Provide sufficient information that allows us to reasonably verify
                                            you are the person about whom we collected personal information or an authorized representative.</span>
                                        </li>
                                        <li class="c0 li-bullet-0"><span class="c2">Describe your request with sufficient detail that allows us to
                                            properly understand, evaluate, and respond to it.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c2">We cannot respond to your request or provide you with personal information if we
                                        cannot verify your identity or authority to make the request and confirm the personal information relates to
                                        you. Making a verifiable consumer request does not require you to create an account with us. We will only
                                        use personal information provided in a verifiable consumer request to verify the requestor&rsquo;s identity
                                        or authority to make the request.</span></p>
                                    <p class="c1"><span class="c6">Response Timing and Format</span></p>
                                    <p class="c1"><span class="c2">We endeavor to respond to a verifiable consumer request within 45 days of its
                                        receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in
                                        writing. If you have an account with us, we will deliver our written response to that account. If you do not
                                        have an account with us, we will deliver our written response by mail or electronically, at your option. Any
                                        disclosures we provide will only cover the 12-month period preceding the verifiable consumer request&rsquo;s
                                        receipt. The response we provide will also explain the reasons we cannot comply with a request, if
                                        applicable. For data portability requests, we will select a format to provide your personal information that
                                        is readily usable and should allow you to transmit the information from one entity to another entity without
                                        hindrance.</span></p>
                                    <p class="c1"><span class="c2">We do not charge a fee to process or respond to your verifiable consumer request
                                        unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a
                                        fee, we will tell you why we made that decision and provide you with a cost estimate before completing your
                                        request.</span></p>
                                    <p class="c1"><span class="c2">If we deny your request, you may have the right to appeal our decision. We will
                                        respond to appeals from Virginia and Connecticut residents within 60 days. We will respond to appeals from
                                        Colorado residents within 45 days.</span></p>
                                    <p class="c1"><span class="c6">Non-Discrimination</span></p>
                                    <p class="c1"><span class="c2">We will not discriminate against you for exercising any of your CCPA, VCDPA, CPA,
                                        CTDPA and UCPA rights. Unless permitted by the CCPA, VCDPA, CPA, CTDPA and UCPA, we will not:</span></p>
                                    <ul class="c13 lst-kix_53ylu0wqdm4a-0 start">
                                        <li class="c0 li-bullet-0"><span class="c2">Deny you goods or services.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Charge you different prices or rates for goods or services,
                                            including through granting discounts or other benefits, or imposing penalties.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Provide you a different level or quality of goods or
                                            services.</span></li>
                                        <li class="c0 li-bullet-0"><span class="c2">Suggest that you may receive a different price or rate for goods or
                                            services or a different level or quality of goods or services.</span></li>
                                    </ul>
                                    <p class="c1"><span class="c6">Changes to Our Privacy Notice</span></p>
                                    <p class="c1"><span class="c2">We reserve the right to amend this privacy notice at our discretion and at any time.
                                        When we make changes to this privacy notice, we will notify you by email or through a notice on our website
                                        homepage.</span></p>
                                    <p class="c1"><span class="c6">Contact Information</span></p>
                                    <p class="c1"><span class="c2">If you have any questions or comments about this notice, our Privacy Statement, the
                                        ways in which we collect and use your personal information, your choices and rights regarding such use, or
                                        wish to exercise your rights, please do not hesitate to contact us at:</span></p>
                                    <p class="c1"><span class="c20"><br /></span><span class="c2">Website: pixelcraftllc.com<br />Email:
                                        privacy@pixelcraftllc.com<br />Postal Address: PixelCraft LLC, Sussex<br />Attn: Privacy Compliance
                                        Officer<br />16192 COASTAL HWY, LEWES.<br />DE, 19958</span></p>
                                    <p class="c1"><span class="c2">Policy Last Revised: Oct 5, 2023</span></p>
                                    <p class="c3 c17"><span class="c9"></span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default PrivacyPolicy;
